// TODO: BE - optional - Pass step objects to progress bar to use ID as key instead of index

import React, { ReactElement, useEffect, useState } from 'react';
import { PageContext } from 'components/common/PageContext';
import { Flex } from '@chakra-ui/react';
import { ProgressBar } from 'components/Welcome/ProgressBar';
import { useUserApi } from 'hooks/api/backend/useUserApi';
import { PageSpinner } from 'components/atoms/PageSpinner';
import { useWelcomeGetStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import * as Sentry from '@sentry/react';
import { UserInfo } from './UserInfo';
import { ReferralSurvey } from './ReferralSurvey';

export type WelcomeScreenOptions = {
  onContinue: (step: number) => void;
  currentStep: number;
};

const STEPS_DONE_INDICATOR = -1;
export const Welcome = (): ReactElement | null => {
  const [currentStep, setCurrentStep] = useState<number | null>(null);

  const { updateFirstTimeUse } = useUserApi();
  const { data: welcomeStepData, refetch: refetchWelcomeStepData, isLoading } = useWelcomeGetStep();
  useEffect(() => {
    if (welcomeStepData) {
      setCurrentStep(welcomeStepData.currentStep);
    }
  }, [welcomeStepData]);

  const handleContinue = async (nextStep: number): Promise<void> => {
    // TODO: add logging/tracking
    if (nextStep === STEPS_DONE_INDICATOR) {
      await updateFirstTimeUse();
    } else setCurrentStep(nextStep);
  };

  const handleBackClick = (): void => {
    if (currentStep === 1) return;
    if (currentStep) {
      setCurrentStep(currentStep - 1);
      refetchWelcomeStepData();
    }
  };

  if (currentStep === null || isLoading) return <PageSpinner />;

  return (
    <PageContext hasBackButton={currentStep !== 1} customOnBackClick={handleBackClick}>
      <Flex
        px="20px"
        py="60px"
        flex={1}
        gap="20px"
        flexDirection="column"
        width="100%"
        minHeight="100%"
      >
        {currentStep !== null && !!welcomeStepData && (
          <>
            <ProgressBar
              currentStep={currentStep}
              stepCount={welcomeStepData.totalSteps}
              hasNonZeroIndex
            />
            <Flex flexGrow={1} flexDirection="column" justifyContent="center">
              {getDynamicContent(
                { currentStep, onContinue: handleContinue },
                welcomeStepData.stepData
              )}
            </Flex>
          </>
        )}
      </Flex>
    </PageContext>
  );
};

const getDynamicContent = (
  options: WelcomeScreenOptions,
  prefilledData: any
): ReactElement | null => {
  switch (options.currentStep) {
    case 1:
      return <UserInfo {...options} prefilledData={prefilledData} />;
    case 2:
      return <ReferralSurvey {...options} />;
    default:
      Sentry.captureException(new Error(`Invalid step: ${options.currentStep}`));
      return null;
  }
};
