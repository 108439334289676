import { useEffect, useState } from 'react';
import {
  ExtractModalOptionsParameters,
  ModalProps,
  ModalPropsVariant,
  ModalVariant,
} from 'types/Modal';
import { useModalContext } from './utils/useModalContext';

type FN = <T extends ModalPropsVariant>(
  variant: T,
  args?: ExtractModalOptionsParameters<ModalProps, T>
) => void;

type ModalService = {
  show: FN;
  hide: () => void;
  activeModal: ModalVariant | null;
};

export const useModalService = (): ModalService => {
  const { setCurrentModalValues, currentModalValues } = useModalContext();
  const [activeModal, setActiveModal] = useState<ModalVariant | null>(null);
  useEffect(() => {
    if (!currentModalValues.isShown) {
      setActiveModal(null);
      return;
    }
    setActiveModal(currentModalValues.variant);
  }, [currentModalValues]);

  const show = <T extends ModalPropsVariant>(
    variant: T,
    args?: ExtractModalOptionsParameters<ModalProps, T>
  ): void => {
    const argKeyList = Object.keys(args || {});
    const tmpArgs = args as Record<string, unknown>;
    const tmpModalValues = currentModalValues as Record<string, unknown>;
    const didArgsChange = argKeyList.some((key) => {
      if (typeof tmpArgs?.[key] === 'function') return false; // skip functions
      return tmpArgs?.[key] !== tmpModalValues?.[key];
    });
    if (currentModalValues.variant === variant && currentModalValues.isShown && !didArgsChange)
      return;
    setActiveModal(variant);
    setCurrentModalValues({ variant, isShown: true, ...args });
  };

  const hide = (): void => {
    setCurrentModalValues({ ...currentModalValues, isShown: false });
  };

  return { show, hide, activeModal };
};
