import { ReactElement } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { PaymentInterval } from '@novaheal/types';
import { SubscriptionButton } from './SubscriptionButton';

type Props = {
  activeButton: PaymentInterval;
  onClick: (subscription: PaymentInterval) => void;
  onSubscribe: () => void;
  isLoading: boolean;
  subscriptionPrices: any[];
};

export const SubscriptionButtons = ({
  activeButton,
  onClick,
  onSubscribe,
  isLoading,
  subscriptionPrices,
}: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

  const subscriptionPriceToButton = (subscriptionPrice: any): ReactElement => {
    const variant = subscriptionPrice.interval;

    let duration;
    let additionalInfo;
    let pricePerMonth;
    let price;
    let oldMonthlyPricing;
    switch (variant) {
      case PaymentInterval.MONTHLY:
        duration = '1 Monat';
        pricePerMonth = `${Number(subscriptionPrice.price).toFixed(2)} €/Monat`;
        oldMonthlyPricing = `14.99 €/Monat`;
        break;
      case PaymentInterval.YEARLY:
        duration = '12 Monate';
        additionalInfo = 'Zahlung jährlich. Jederzeit kündbar.';
        pricePerMonth = `${Math.floor((subscriptionPrice.price / 12) * 100) / 100} €/Monat`;
        price = `${subscriptionPrice.price} €`;
        oldMonthlyPricing = `10.99 €/Monat`;
        break;
      case PaymentInterval.BIWEEKLY:
        duration = '2 Tage';
        additionalInfo = 'Dies ist ein Testprodukt. Nur zum internen Gebrauch.';
        pricePerMonth = `${Number(2 * subscriptionPrice.price).toFixed(2)} €/Monat`;
        price = `${subscriptionPrice.price} €`;
        break;
      default:
        duration = 'Unknown duration';
        pricePerMonth = '';
    }

    return (
      <SubscriptionButton
        key={subscriptionPrice.id}
        isActive={activeButton === variant}
        onClick={() => onClick(variant)}
        variant={variant}
        price={price}
        pricePerMonth={pricePerMonth}
        additionalInfo={additionalInfo}
        duration={duration}
        oldMonthlyPricing={oldMonthlyPricing}
      />
    );
  };

  return (
    <Flex gap="25px" width="100%" flexDirection="column">
      <Flex gap="15px" width="100%" flexDirection="column">
        {isDesktop && <Text color="grey.textPrimary">Wähle deinen Plan:</Text>}
        {subscriptionPrices ? subscriptionPrices.map(subscriptionPriceToButton) : null}
      </Flex>
      {isDesktop && (
        <Button
          variant="primary"
          width="220px"
          alignSelf="center"
          onClick={onSubscribe}
          isLoading={isLoading}
        >
          Abonnieren
        </Button>
      )}
    </Flex>
  );
};
