import { AxiosError } from 'axios';
import { axios } from 'utils/axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { WelcomeScreenDTO, WelcomeScreenGet, WelcomeScreenPost } from 'types/WelcomeScreen';

const route = '/user/welcome-progress';

const generateError = (e: AxiosError<any>): WelcomeScreenGet => {
  const message = e.response?.data.message ?? '';
  return {
    currentStep: 0,
    nextStep: 0,
    totalSteps: 0,
    stepData: {
      status: 'NOT_AVAILABLE',
      message,
    },
  };
};
const postStep = async (postData: WelcomeScreenPost): Promise<WelcomeScreenGet> => {
  // try {
  const { currentStep, data } = postData;
  const routeWithStep = currentStep ? `${route}/${currentStep}` : route;
  const { data: updatedData } = await axios.post<WelcomeScreenDTO>(routeWithStep, {
    data,
  });

  return {
    stepData: updatedData.data,
    totalSteps: updatedData.totalSteps,
    nextStep: updatedData.nextStep,
    currentStep: updatedData.currentStep,
  };
};

const getStep = async (step?: number): Promise<WelcomeScreenGet> => {
  try {
    const routeWithStep = step ? `${route}/${step}` : route;
    const { data } = await axios.get<WelcomeScreenDTO>(routeWithStep);
    const { totalSteps, nextStep, currentStep, data: returnData } = data;

    return { totalSteps, nextStep, currentStep, stepData: returnData };
  } catch (e: unknown) {
    const axiosError = e as AxiosError;
    return generateError(axiosError);
  }
};

export const useWelcomeGetStep = (step?: number): UseQueryResult<WelcomeScreenGet, unknown> => {
  return useQuery({
    queryKey: [`getWelcomeStep-${step}`], // Key for caching the query
    queryFn: () => getStep(step),
    retry: 1,
  });
};

export const useWelcomePostStep = (): UseMutationResult<
  WelcomeScreenGet,
  AxiosError<unknown>,
  WelcomeScreenPost,
  unknown
> => {
  return useMutation({
    mutationFn: postStep,
  });
};
