import { Button, ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { ModalVoucherProps, SharedModalProps } from 'types/Modal';
import { Form, InputField } from 'components/common/Form';
import { FilesIcon } from 'assets/icons/Icons';
import { useRedeemVoucher } from 'hooks/api/backend/voucher/useRedeemVoucher';
import { useUser } from 'utils/auth';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { SubscriptionStatus, VoucherUsageStatusType } from '@novaheal/types';
import { ToastVariant } from 'types/Toast';
import { VoucherStatusType } from 'types/StatusTypes';
import { toastContent } from 'config/toast';
import { CustomModal } from '../CustomModal';

type Voucherinput = {
  shortCode: string;
};

type Props = SharedModalProps & ModalVoucherProps;

export const VoucherModal = ({ handleClose, isShown }: Props): ReactElement => {
  const redeemVoucher = useRedeemVoucher();
  const { refetch: refetchUser } = useUser();
  const toast = useNovaToast();

  const showToast = (
    status: VoucherUsageStatusType,
    previousSubscriptionStatus: SubscriptionStatus | undefined
  ): void => {
    const isSuccess = status === VoucherUsageStatusType.SUCCESS;
    const toastVariant = isSuccess ? ToastVariant.SUCCESS : ToastVariant.ERROR;
    const toastStatus =
      status === VoucherUsageStatusType.SUCCESS &&
      previousSubscriptionStatus === SubscriptionStatus.PAID
        ? VoucherStatusType.PREVIOUSLY_PAID_SUB
        : status;

    toast.show({
      title: toastContent.voucher[toastStatus].title,
      description: toastContent.voucher[toastStatus].description,
      variant: toastVariant,
    });
  };

  const handleSubmit = async (values: Voucherinput): Promise<void> => {
    const { shortCode } = values;
    const { status: voucherCodeStatus, previousSubscriptionStatus } =
      await redeemVoucher.mutateAsync(shortCode);
    if (voucherCodeStatus === VoucherUsageStatusType.SUCCESS) {
      handleClose();
    }
    showToast(voucherCodeStatus, previousSubscriptionStatus);
    await refetchUser();
  };

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      isCentered
      maxWidth={{ base: '80%', md: '660px' }}
    >
      <ModalBody display="flex" gap="30px" flexDirection="column" alignItems="center" p="40px 30px">
        <Flex direction="column" gap="15px" textAlign="center">
          <Text textStyle="h4.med" color="blue.textHeader">
            Organisations-Lizenz aktivieren
          </Text>
          <Text textStyle="body.16.reg" color="grey.tertiary">
            Den Code erhältst Du von deiner Organisation. Schalte alle Lerninhalte kostenlos frei.
          </Text>
        </Flex>
        <Box width="100%">
          <Form onSubmit={(values) => handleSubmit(values)}>
            {({ register }) => {
              return (
                <Flex flexDirection="column" gap="40px" alignItems="center" pt="5px" width="100%">
                  <InputField
                    type="text"
                    registration={register('shortCode')}
                    leftElement={<FilesIcon color="grey.40" />}
                    placeholder="Organisationslizenz"
                    _focus={{ bg: 'bg.input', borderColor: 'green.primary' }}
                  />
                  <Flex gap="20px" justifyContent="flex-end" width="100%">
                    <Button variant="base" onClick={handleClose} w={{ base: 'full', md: '220px' }}>
                      Abbrechen
                    </Button>
                    <Button type="submit" variant="primary" w={{ base: 'full', md: '220px' }}>
                      Aktivieren
                    </Button>
                  </Flex>
                </Flex>
              );
            }}
          </Form>
        </Box>
      </ModalBody>
    </CustomModal>
  );
};
