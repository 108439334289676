import { ReactElement, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Box, Button, Stack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import Joi from 'joi';
import { Form } from 'components/common/Form';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { Separator } from 'components/common/Separator';
import { toastContent } from 'config/toast';
import { axios, caughtErrorGlobally } from 'utils/axios';
import { RegisterCredentialsStep1Dto } from 'types/dto/RegisterCredentialsStep1.dto';
import { EmailInputField } from 'components/common/Form/EmailInputField';
import { useFeatureFlagsContext } from 'hooks/utils/useFeatureFlagContext';
import { RegisterStepProps } from 'pages/auth/Registration';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { EmailStatusType } from 'types/StatusTypes';
import { RegistrationLayout } from './RegistrationLayout';
import { GoogleButton } from '../atoms/GoogleButton';

type RegisterStep1Inputs = {
  email: string;
};

const schema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
});

export const RegisterStep1 = ({
  nextStep,
  handleChanges,
  values,
}: RegisterStepProps): ReactElement => {
  const toast = useNovaToast();
  const featureFlags = useFeatureFlagsContext();
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

  const handleSubmit = async (registerValues: RegisterCredentialsStep1Dto): Promise<void> => {
    setContinueButtonDisabled(true);
    try {
      const validateMail = await axios.post('/auth/validate', {
        email: registerValues.email,
      });

      setContinueButtonDisabled(false);

      if (validateMail.data === 'USED') {
        toast.show(toastContent.email[EmailStatusType.TAKEN]);
      } else {
        nextStep();
      }
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show({
          variant: ToastVariant.ERROR,
        });
      }
    }
  };

  return (
    <RegistrationLayout>
      <Stack spacing={10} width="100%">
        <Box px="20px">
          <Form<RegisterStep1Inputs, typeof schema>
            onSubmit={handleSubmit}
            schema={schema}
            autoComplete="on"
          >
            {({ register, formState, watch, resetField }) => (
              <Stack spacing="30px" my={0}>
                <EmailInputField
                  error={formState.errors.email as FieldError}
                  placeholder="Email"
                  register={() =>
                    register('email', {
                      onChange: handleChanges('email'),
                      value: values.email.toLowerCase(),
                    })
                  }
                  reset={() => resetField('email')}
                  value={watch('email')}
                />
                <Box>
                  <Button
                    variant="primary"
                    type="submit"
                    w="full"
                    m={0}
                    disabled={continueButtonDisabled && !formState.isValid}
                  >
                    Weiter
                  </Button>
                </Box>
              </Stack>
            )}
          </Form>
        </Box>
        <Box>
          <Separator text="Oder" />
        </Box>
        {featureFlags.isEnabled('isGoogleAuthEnabled') && (
          <Box px="20px">
            <GoogleButton />
          </Box>
        )}
      </Stack>
    </RegistrationLayout>
  );
};
