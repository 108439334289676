import { User } from '@novaheal/types';
import { AppleRegisterCredentials } from '@novaheal/types/lib/auth/registerCredentials/appleRegisterCredentials';
import { axios } from 'utils/axios';
import storage from 'utils/storage';
import { logFirebaseUserFlow } from 'utils/firebaseLogs';

export async function registerWithApple(credentials: AppleRegisterCredentials): Promise<User> {
  const response = await axios.post('apple-authentication/register', {
    ...credentials.appleUser,
  });
  const { user, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseUserFlow('register', { ourUserId: user.id, registerMethod: 'apple' }, user); // named ourUserId, as userId is blocked by ga
  return user;
}
