import {
  Box,
  Button,
  Flex,
  ModalBody,
  Text,
  Link as ExternalLink,
  Spacer,
  CloseButton,
} from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { ModalSubscribeVariantProps, SharedModalProps } from 'types/Modal';
import { ExternalRoutes } from 'types/Routes';
import { PaymentInterval } from '@novaheal/types';

import { css } from '@emotion/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { useCreateSubscription } from 'hooks/api/backend/subscription/useCreateSubscription';

import { toastContent } from 'config/toast';
import { CustomModal } from '../../CustomModal';
import { SubscriptionBenefits } from './SubscriptionBenefits';
import { SubscriptionButtons } from './SubscriptionButtons';
import { SubscriptionSlider } from './SubscriptionSlider';
import { useSubscriptionPrices } from '../../../../../hooks/api/backend/subscription/useSubscriptionPrices';
import { useNovaToast } from '../../../../../hooks/utils/useNovaToast';
import { ToastVariant } from '../../../../../types/Toast';

type Props = SharedModalProps & ModalSubscribeVariantProps;

export const SubscribeModal = ({ handleClose, isShown, promoCode }: Props): ReactElement | null => {
  const [activeButton, setActiveButton] = useState<PaymentInterval>(PaymentInterval.YEARLY);
  const { isPending: isLoading, mutateAsync: mutateAsyncSubscription } =
    useCreateSubscription(activeButton);
  const toast = useNovaToast();
  const isCouponExpired = promoCode?.expirationDate
    ? new Date(promoCode.expirationDate) < new Date()
    : false;
  const [shouldShowCouponBanner, setShouldShowCouponBanner] = useState(
    !!promoCode?.id && !isCouponExpired
  );

  const { data: subscriptionPrices, isError: subscriptionPricesError } = useSubscriptionPrices();

  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const subscribeWidth = isDesktop ? '60%' : '100%';

  useEffect(() => {
    if (subscriptionPricesError) {
      toast.show({
        variant: ToastVariant.ERROR,
      });
    }
  }, [subscriptionPricesError, toast]);

  useEffect(() => {
    if (promoCode) {
      // TODO: think how to implement a redemption limit without asking stripe for it (without info about redemption count)
      // const redemptionLimitReached = promoCode.maxRedemptions;
      // if (redemptionLimitReached) {
      //   toast.show({
      //     ...toastContent.subscription.couponValidations.couponRedemptionLimitReached,
      //   });
      // }
    }
  }, [promoCode, toast]);

  const handleSubscribe = async (): Promise<void> => {
    const checkoutLink = await mutateAsyncSubscription(promoCode?.id);

    if (checkoutLink) window.location.replace(checkoutLink);
  };

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      isCentered
      hideHeader={isDesktop}
      isFullscreen={!isDesktop}
      maxWidth="850px"
      floatingCross={isDesktop}
    >
      <ModalBody
        p="0"
        css={css`
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        <Flex flexDirection={{ base: 'column-reverse', md: 'row' }} width={subscribeWidth}>
          {!isDesktop && (
            <Box
              position="sticky"
              bottom="0"
              display="grid"
              placeItems="center"
              width="100%"
              background="linear-gradient(180deg, rgba(253, 252, 254, 0) 0%, #FDFCFE 15.1%)"
              py="30px"
            >
              <Button
                variant="primary"
                sx={{
                  '@supports (-webkit-touch-callout: none)': {
                    marginBottom: '20px',
                  },
                }}
                width="220px"
                onClick={handleSubscribe}
                isLoading={isLoading}
              >
                Abonnieren
              </Button>
            </Box>
          )}
          <Flex
            width="100%"
            bg={{ base: 'bg.light', md: 'bg.darken' }}
            gap="40px"
            p={{ base: '30px 25px 0px', md: '45px 25px 30px' }}
            flexDirection="column"
          >
            {shouldShowCouponBanner && (
              <>
                <Box
                  bgColor="#FCF3D1"
                  position="absolute"
                  top="50px"
                  left="20px"
                  right="55px"
                  p="10px 40px 10px 20px"
                  zIndex="100"
                >
                  <CloseButton
                    position="absolute"
                    top="50%"
                    transform="translateY(-50%)"
                    right="10px"
                    onClick={() => setShouldShowCouponBanner(false)}
                  />
                  <Text textStyle="heading.24" textAlign="center">
                    🎉&nbsp;Dein Promo-Code wird automatisch nach Auswahl des Abonnements
                    angewendet!&nbsp;🎉
                  </Text>
                </Box>
                <Spacer height="20px" minH="20px" />
              </>
            )}
            <SubscriptionButtons
              activeButton={activeButton}
              onClick={setActiveButton}
              onSubscribe={handleSubscribe}
              isLoading={isLoading}
              subscriptionPrices={subscriptionPrices}
            />
            <SubscriptionBenefits />

            <Text textStyle="body.12.reg" textAlign="center">
              Mit deiner Bestellung erklärst du dich mit unseren{' '}
              <ExternalLink href={ExternalRoutes.TERMS} color="blue.highlighted" target="_blank">
                Nutzungsbedingungen und Widerrufsbestimmungen
              </ExternalLink>{' '}
              einverstanden
            </Text>
          </Flex>
          {!isDesktop && (
            <Box>
              <SubscriptionSlider />
            </Box>
          )}
        </Flex>
      </ModalBody>
      {isDesktop && (
        <Box
          position="absolute"
          height="500px"
          width="40%"
          left="100%"
          top="50%"
          transform="translateY(-50%) translateX(-100%)"
        >
          <SubscriptionSlider />
        </Box>
      )}
    </CustomModal>
  );
};
